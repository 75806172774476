import React from "react";

import PageTitle from "../SingleComponents/PageTitle";
import AttorneyDetailsArea from "../SingleComponents/AttorneyDetailsArea";

export default function AttorneyDetailsPage() {
  return (
    <div>
      <PageTitle title="Attorney Details"></PageTitle>
      <AttorneyDetailsArea></AttorneyDetailsArea>
    </div>
  );
}
