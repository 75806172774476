import React from "react";

import PageTitle from "../SingleComponents/PageTitle";
import PracticeDetails from "../SingleComponents/PracticeDetailArea";

export default function PracticeDetailPage() {
  return (
    <div>
      <PageTitle title="Practice Detail"></PageTitle>
      <PracticeDetails></PracticeDetails>
    </div>
  );
}
