import React from "react";
import { Link } from "react-router-dom";

function TeamArea() {
  return (
    <section className="team-area mt-5">
      <div className="container">
        <div className="section-title">
          <span>TEAM MEMBER</span>
          <h2>Meet Our Expert Attorneys</h2>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <img src="assets/img/home-one/team/1.jpg" alt="Team" />
              <div className="team-inner">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <h3>
                  <Link to="/attorney-detail">İSİM SOYİSİM </Link>
                </h3>
                <span>AVUKAT</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <img src="assets/img/home-one/team/2.jpg" alt="Team" />
              <div className="team-inner">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <h3>
                  <Link to="/attorney-detail">İSİM SOYİSİM </Link>
                </h3>
                <span>AVUKAT</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <img src="assets/img/home-one/team/3.jpg" alt="Team" />
              <div className="team-inner">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <h3>
                  <Link to="/attorney-detail">İSİM SOYİSİM </Link>
                </h3>
                <span>AVUKAT</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="team-item">
              <img src="assets/img/home-one/team/6.jpg" alt="Team" />
              <div className="team-inner">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <h3>
                  <Link to="/attorney-detail">İSİM SOYİSİM </Link>
                </h3>
                <span>AVUKAT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamArea;
